.Login .lander {
  max-width: 350px;
  margin: auto;
}
.Login .lander .col-md-12{
  margin: 0 1em 0 1em;
}

.Login .lander button {
  margin-top: 25px;
}

.Login p {
  text-align: center;
}

.Login .hl-or {
  line-height: 0;
  border-bottom: grey 1px solid;
  text-align: center;
  margin: 1.5em 0 0 0;
}

.Login .hl-or span {
  background-color: white;
  padding: .5em;
  font-size: 80%;
}

.Login .btn-google {
  width: 100%;
  color: black;
  border-color: grey;
  position: relative;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
}

.Login .btn-google:hover {
  background-color: white;
  border-color: black;
}

.Login .btn-google svg {
  position: absolute;
  left: 8px;
}

.Login .btn-facebook {
  width: 100%;
  color: white;
  background-color: #3b5998;
  position: relative;
  font-weight: 500;
  margin-top: 16px !important;
}

.Login .btn-facebook:hover {
  background-color: #334b7e;
}

.Login .btn-facebook svg {
  position: absolute;
  left: 8px;
  margin-top: 2px;
}

.Login .btn-facebook svg path {
  fill: white;
}

.Login .social-warning {
  margin: 0.3em 0 2em 0;
}
