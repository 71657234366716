.NotFound h1 {
  font-size: 40px;
  margin-top: 2em;
}
.NotFound p {
  margin-top: 2em;
}
.NotFound li {
  line-height: 2em;
}
