body {
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6 {
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 12px;
}
input[type=file] {
  width: 100%;
}
