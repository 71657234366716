
#cookies-consent {
    position: fixed;
    background: #f5f7f9;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: .7em;
    box-shadow: #3c466638 0px 2px 15px 0px;
}
#cookies-consent p {
    margin: 0;
    flex-shrink: 1;
    text-align: center;
}
#cookies-consent .btn {
    flex-shrink: 0;
}

