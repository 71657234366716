body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  height: 100%;
  width: 100%;
}

h1, h2, h3, h4 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-top: 15px;
}
h1 {
  font-size: 28px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 26px;
}
label {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.1em;
}
label.form-check-label {
    font-weight: 400;
}
.custom-control-input:checked ~ .custom-control-label::before {
    background-color: #95a5a6;
    border-color: #95a5a6;
}

input.form-control, select.form-control, textarea.form-control {
  min-height: 45px;
  color: rgb(107, 107, 107);
  font-size: 14px;
  border: 2px solid rgb(220, 228, 236);
}

input.form-control:hover {
  border: 2px solid rgb(208, 212, 216);
}

input.form-control:focus, select.form-control:focus, textarea.form-control:focus {
  box-shadow: none;
  border: 2px solid #2c3e50;
  color: rgb(58, 58, 58);
}

.btn {
  min-height: 45px;
}
a.btn {
  padding-top: 9px;
}
.btn-link {
  min-height: 0;
  padding: 0;
}

/*.btn-toolbar .btn {
  margin-left: 10px;
}
.btn-toolbar .btn:first-child {
  margin-left: 0;
}*/

.navbar {
  padding: 10px 16px 9px 16px;
}
.navbar-nav > a {
  margin-left: 15px;
}

.navbar-brand {
  font-family: "Roboto";
  height: 35px;
  margin-top: -7px;
  font-size: 1.4em;
}
.navbar-brand svg {
  margin: -2px 5px 2px 0;

}
.navbar-brand path, .navbar-brand rect {
  stroke: white;
}
.navbar-brand:hover path, .navbar-brand:hover rect, .navbar-brand:focus path, .navbar-brand:focus rect {
  stroke: #18BC9C;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: #18BC9C !important;
}

.navbar.fixed-top {
    box-shadow: 0px 1px 9px 1px #00000069;
}

.navbar-toggler {
  border: none;
}

.main {
  background-color: white;
  min-height: calc(100vh - 128px);
}

#footer {
  background-color: #5a5f5f;
  width: 100%;
  overflow: auto;
  margin-top: 1em;
}

#footer div {
  color: white;
  text-align: center;
  margin: 1em auto 1em auto; }

#footer div a{
  color: white;}

#footer div a:hover{
  color: white;
  text-decoration: underline;
}


.modal-backdrop.fade {
  opacity: 0;
  background-color: #2c3e50;
  -webkit-transition: all .1s ease-in;
  -o-transition: all .1s ease-in;
  transition: all .1s ease-in; }

.modal-backdrop.fade.show {
  opacity: 0.5; }

.modal-dialog {
  transform: none !important;
  top: 25vh; }

.modal-large .modal-dialog {
  top: 10vh; }

.modal-content {
  box-shadow: none !important;
  border-radius: 0 !important;
  border-width: 0; }

.fade .modal-content {
  transform: scale(0.7);
  opacity: 0;
  -webkit-transition: all .1s ease-out;
  -o-transition: all .1s ease-out;
  transition: all .1s ease-out; }

.fade.show .modal-content {
  opacity: 1;
  transform: scale(1); }


.progress-modal {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(32, 32, 32, 0.77);
  width: 20em;
  padding: 1em;
  color: white;
  border-radius: 5px;
  z-index: 110;
}
.progress-modal span {
  display: block;
  margin-bottom: .8em;
}
.progress-modal .progress {
  margin-bottom: 0;
}
.progress-modal.fading {
  transition: opacity 1s;
  opacity: 0;
}

.strats-params-input button {
  position: absolute;
  right: 10px;
  top: 23px;
  cursor: default !important;
}
.strats-params-input button svg {
  color: hsl(0,0%,80%);
  width: 20px;
  height: 20px;
}
.strats-params-input button:hover svg {
  color: hsl(0,0%,70%);
  width: 20px;
  height: 20px;
}
.strats-params-input:focus path {
  fill: #2c3e50;
}

.btn-toolbar .btn {
  margin-right: 10px;
}


