
.Home .lander .subscribe .btn-toolbar {
  margin: 1.2em 0 .3em 0;
}

.Home .lander .subscribe .btn {
  width: 8em;
  margin-right: .6em;
}

.Home .lander .subscribe #learn-more {
  background-color: white;
  border-width: 2px;
  color: #95a5a6;
}

.Home .lander .subscribe #learn-more:hover {
  color: #6b7777;
}


@media (min-width: 991px) {
  .Home .lander {
    background-image: url("splash-large.jpg");
    background-size: cover;
    background-position: 50% 25%;
    min-height: 30em;
  }
  .Home .lander-ovr {
    background-color: rgba(0, 94, 189, 0.3);
    height: 100%;
    width: 100%;
    min-height: 30em;
  }
  .Home .lander .title {
    margin-left: 3em;
    margin-top: 5em;
  }
  .Home .lander .title span {
    display: block;
    line-height: 1.2em;
    margin-bottom: .1em;
    font-family: "Roboto", sans-serif;
    color: white;
    font-size: 6em;
    font-weight: 700;
    text-shadow: 0 0 1px rgb(128, 116, 108);
    transform: translate(0, 50px);
    opacity: 0;
    transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  }
  .loaded .Home .lander .title span {
    transform: translate(0, 0);
    opacity: 1;
  }
  .Home .lander .title div{
    transform: translate(0, 70px);
    opacity: 0;
    max-width: 350px;
    transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  }
  .loaded .Home .lander .title div {
    transform: translate(0, 0);
    opacity: 1;
  }

  .Home .lander .title h1{
    font-size: 1.3em;
    line-height: 1.6em;
    padding: 0.5rem 0.6rem 0.5rem 0.6rem;
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    background-color: #18bc9c;
    display: inline;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
  }
  .Home .lander .rightcol {
    text-align: center;
  }
  .Home .lander .subscribe {
    text-align: left;
    font-size: 1.1em;
    margin: 8em auto 1em auto;
    width: 70%;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    padding: 20px;
  }
}

@media (max-width: 991px) {
  .Home .lander {
    background-image: linear-gradient(to bottom, #2c3e4f, #4e657c);
  }
  .Home .lander .title span {
    display: none;
  }
  .Home .lander .rightcol {
    text-align: center;
  }
  .Home .lander .subscribe {
    text-align: left;
    font-size: 1.1em;
    margin: 2em auto 1em auto;
    background: white;
    border-radius: 10px;
    padding: 20px;
  }
  .Home .lander .subscribe button, .Home .lander .subscribe input, .Home .lander .subscribe .form-group{
    width: 100% !important;
  }
  .Home .lander .subscribe input {
    margin-bottom: 15px;
  }
  .Home .lander .title h1{
    margin: 1em 1.5em 0 0;
    font-size: 1.6em;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    line-height: 1.3em;
    color: #ffffff;
  }
}


.Home .zoom {
  transition: .15s;
}

.Home .zoom:hover {
  transform: scale(1.02);
}

.Home .features {
  text-align: center;
}

.Home .features .col-md-4 {
  padding: 20px;
}
.Home .features .card {
  border: 1px solid #eaedf0;
  box-shadow: #f5f5f5 0px 2px 15px 0px;
  padding: 10px;
}
.Home .features .card-body {
    padding: 5px;

}

@media (max-width: 991px) {
    .Home .features .card {
        min-width: 75vw;
        margin-bottom: 15px;
    }
}
.Home .features h4{
  margin: 1.5em auto 0.5em auto;
  font-family: "Roboto";
  color: rgb(103, 103, 103);
  font-size: 1.5em;
}
@media (min-width: 991px) {
  .Home .features h4{
    margin: 2em auto 1.5em auto;
    width: 60%;
  }
}

.Home .features h3{
  font-family: "Roboto";
  font-weight: 600;
}
.Home .features h3 svg {
  color: #18bc9c;
}

.Home .features ul {
  list-style: none;
  padding: 0;
  margin: 0 0 0 1.5em;
  font-size: 1.1em;
  font-family: "Open Sans";
}
.Home .features li {
  padding: 0 0 0.5em 1em;
  text-indent: -.7em;
  text-align: left;
  line-height: 1.4em;
}

.Home .features li::before {
  content: "• ";
  font-size: 1.3em;
  color: #18bc9c;
}

.Home .features em {
  color: #16a78a;
  font-style: normal;
  font-weight: 600;
}

.Home .whatwedo h3, .Home .whatwedo h4 a{
  color: #2c3e50;
}

.Home .whatwedo h3:after {
  content: " ";
  display: block;
  background-color: #18bc9c;
  width: 4vw;
  height: 2px;
  margin: 0.3em auto 0.2em auto;
}


.Home .pricing {
  background: #eeedeb;
  padding: 2em 0 2em 0;
  text-align: center;
  margin-bottom: -1em;
}

.Home .pricing h2 {
  color: #2c3e50;
  display: block;
  margin: 0 auto 1em auto;
}


@media (max-width: 991px) {
  .Home .pricing .card {
    min-width: 70vw;
    margin-bottom: 15px;
  }
}

.Home .pricing .card-body {
  text-align: left;
}

.Home .pricing .card-body em {
  color: #16a78a;
  font-style: normal;
  font-weight: 600;
}
.Home .pricing .card-body svg {
  color: #16a78a;
}

.Home .pricing .card-header {
  background: white;
  text-align: center;
  border-bottom: 1px solid #eaedf0;
  color: #16a78a;
  font-size: 18px;
  margin: 0;
}

.Home .pricing .card-footer {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: white;
  border-radius: 0;
  border: 0;
  background: #18bc9c;
  font-size: 1.3em;
}

.Home .pricing .nota{
  max-width: 450px;
  margin: 2em auto 0 auto;
}

.Home .pricing .btn{
  margin-top: 2em;
}
